var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Dynamic } from './component';
import { DspService } from '../dsp.service';
import { DLUtil } from '../../base/dl.util';
var SFMainComponent = /** @class */ (function (_super) {
    __extends(SFMainComponent, _super);
    function SFMainComponent(dspService) {
        var _this = _super.call(this) || this;
        _this.dspService = dspService;
        return _this;
    }
    SFMainComponent.prototype.setupFromControl = function () {
        this.component.loaded = true;
        // tel my parent I'm loaded.
        this.loaded.emit(this.component.id);
    };
    SFMainComponent.prototype.getFormControl = function () {
        return undefined;
    };
    Object.defineProperty(SFMainComponent.prototype, "val", {
        get: function () {
            return this.component.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SFMainComponent.prototype, "name", {
        get: function () {
            return this.dspService.currentData.leadName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SFMainComponent.prototype, "minCover", {
        get: function () {
            return this.val.options[0][0].benefit_amount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SFMainComponent.prototype, "maxCover", {
        get: function () {
            return this.val.options[0][this.val.options[0].length - 1].benefit_amount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SFMainComponent.prototype, "increments", {
        get: function () {
            if (this.val.options[0].length > 1) {
                return (this.val.options[0][1].benefit_amount - this.val.options[0][0].benefit_amount);
            }
            return 5000;
        },
        enumerable: true,
        configurable: true
    });
    SFMainComponent.prototype.formatDisplay = function (value) {
        return DLUtil.compactFormat(value, false);
    };
    SFMainComponent.prototype.updateCover = function (val) {
        var _this = this;
        this.val.options[0].forEach(function (opt) {
            if (val.value === opt.benefit_amount) {
                _this.val.benefit_amount = opt.benefit_amount;
                _this.val.premium_amount = opt.premium_amount;
            }
        });
    };
    return SFMainComponent;
}(Dynamic));
export { SFMainComponent };
