import { EventEmitter } from '@angular/core';
import { DependantVO, ModalData } from './sf-summary.service';
import { MatDialog } from '@angular/material';
import { isNullOrUndefined } from "util";
import { SFDependantModalComponent } from "./sf-dependant-modal.component";
import { Log } from "ng2-logger/client";
var log = Log.create('SF-Summary');
var SFBaseSummaryComponent = /** @class */ (function () {
    function SFBaseSummaryComponent(dialog) {
        this.dialog = dialog;
        this.add = new EventEmitter();
        this.edit = new EventEmitter();
        this.remove = new EventEmitter();
    }
    SFBaseSummaryComponent.prototype.openModal = function (dependant, edit, main) {
        var _this = this;
        var data = {
            spId: this.spId,
            benefitId: this.benefitId,
            leadName: this.leadName,
            edit: edit,
            main: main,
            dependant: dependant,
            products: this.products,
            dependants: this.dependants
        };
        this.dialogRef = this.dialog.open(SFDependantModalComponent, {
            disableClose: true,
            panelClass: 'dl-modal',
            maxWidth: '99vw',
            position: { top: '20px' },
            data: data
        });
        this.dialogRef.beforeClose().subscribe(function (res) {
            if (res instanceof ModalData) {
                if (res.edit) {
                    _this.edit.emit({ main: res.main, dependant: res.dependant });
                }
                else {
                    _this.add.emit(res.dependant);
                }
            }
        });
    };
    SFBaseSummaryComponent.prototype.clickAdd = function () {
        this.openModal(new DependantVO(), false, false);
    };
    SFBaseSummaryComponent.prototype.clickEdit = function (dependant, main) {
        this.openModal(dependant, true, main);
    };
    SFBaseSummaryComponent.removeDependant = function (dependants, id) {
        var list = [];
        dependants.forEach(function (d) {
            if (d.id !== id) {
                list.push(d);
            }
        });
        return list;
    };
    SFBaseSummaryComponent.prototype.clickRemove = function (dependant) {
        dependant.deleting = true;
        this.remove.emit(dependant);
    };
    SFBaseSummaryComponent.prototype.getBadge = function (d) {
        if (!isNullOrUndefined(d) && !isNullOrUndefined(d.rel) && d.rel.length >= 1) {
            return d.rel.substr(0, 1);
        }
        return '?';
    };
    SFBaseSummaryComponent.prototype.getHint = function (d) {
        if (!isNullOrUndefined(d) && !isNullOrUndefined(d.rel) && d.rel.length >= 1) {
            return d.rel;
        }
        return 'Unknown';
    };
    Object.defineProperty(SFBaseSummaryComponent.prototype, "totalPremium", {
        get: function () {
            var total = 0;
            if (!isNullOrUndefined(this.dependants)) {
                this.dependants.forEach(function (d) {
                    total += d.premium_amount;
                });
            }
            return total;
        },
        enumerable: true,
        configurable: true
    });
    return SFBaseSummaryComponent;
}());
export { SFBaseSummaryComponent };
