import { Component, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';
import { PageComponentVO, PageInfoVO, ProductValuesVO, ValidationResult } from '../page.data.vo';
import { isNullOrUndefined } from 'util';
import { TextComponent } from '../component/text.component';
import { RsaIdComponent } from '../component/rsa.id.component';
import { SelectComponent } from '../component/select.component';
import { ProductComponent } from '../component/product.component';
import { InfoComponent } from '../component/info.component';
import { TelComponent } from '../component/tel.component';
import { EmailComponent } from '../component/email.component';
import { BankingComponent } from '../component/banking.component';
import { BeneficiariesComponent } from '../component/beneficiaries.component';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { LinkButtonComponent } from '../component/link.button.component';
import { DspService } from '../dsp.service';
import { ResumeButtonComponent } from '../component/resume.button.component';
import { Log, Logger } from 'ng2-logger/client';
import { NumberComponent } from '../component/number.component';
import { DatePickerComponent } from '../component/date-picker.component';
import { ParentEventService } from '../component/component';
import { AltContinueComponent } from '../component/alt.continue.component';
import { RadioGroupComponent } from '../component/radio.group.component';
import { CheckboxGroupComponent } from '../component/checkbox.group.component';
import { CheckboxComponent } from '../component/checkbox.component';
import { BmiComponent } from '../component/bmi.component';
import { BeneficiariesUKComponent } from '../component/beneficiaries-uk.component';
import { DotOrgComponent } from '../component/dot-org.component';
import { InfoCardComponent } from '../component/info-card.component';
import { StripeElementComponent } from '../component/stripe.element.component';
import { WindowRef } from '../../base/window.ref';
import { DateComponent } from '../component/date.component';
import { UkPostcodeComponent } from '../component/uk.postcode.component';
import { CustomiseCoverComponent } from '../component/customise-cover.component';
import { OfferInfoCardComponent } from '../component/offer-info-card.component';
import { AjaxButtonComponent } from '../component/ajax.button.component';
import { Subscription } from 'rxjs';
import { MailDraftDocInfoComponent } from '../component/mail.draft.doc.info.component';
import { MLFMainComponent } from '../component/mlf-main.component';
import { MLFSummaryComponent } from '../component/mlf-summary.component';
import { RsaIdDobComponent } from '../component/rsa.id.dob.component';
import { HiddenComponent } from '../component/hidden.component';
import { LLCMainComponent } from '../component/llc-main.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { DialogData, NotesDialogComponent } from '../../sp/confirmation/confirmation.component';
import { RadioGroupHorizontalComponent } from '../component/radio.group.horizontal.component';
import { LlcAdditionalProductsComponent } from '../component/llc-additional-products.component';
import { QqComponent } from 'src/app/qq/qq.component';
import { OkDisclosureComponent } from '../component/ok-disclosure.component';
import {PantSizeComponent} from '../component/pantsize.component';
import {SFMainComponent} from '../component/sf-main.component';
import {SFSummaryComponent} from '../component/sf-summary.component';

const log = Log.create('PageComponent');
/**
 * Created by corneliusbotha on 2017/07/18.
 */

@Component({
    selector: 'dsp-page',
    templateUrl: 'page.component.html',
})
export class PageComponent implements OnDestroy {

    pageForm: FormGroup;
    private busySubscription: Subscription;
    addressNotes = '';
    caseNotes = '';

    constructor(
        private fb: FormBuilder,
        private dspService: DspService,
        private parentEventService: ParentEventService,
        private windowRef: WindowRef,
        public dialog: MatDialog) {
        this.pageForm = this.fb.group({ placeholder: '' });
        this.busySubscription = this.parentEventService.getPageBusyObservable().subscribe((event) => {
            this.disable = event;
            if (this.busy && !event) {
                this.busy = false;
            }
        });
    }

    rows: Row[] = [];
    pageValid = false;
    busy = false;
    disable = false;
    showCaseCreationOptions: boolean = false;
    createCaseEnabled: boolean = false;
    casesCreated: boolean = false;
    caseOptionsDisabled: boolean = false;

    _pageInfo: PageInfoVO;
    formId = '';
    @Input()
    set pageInfo(data: PageInfoVO) {
        log.info('set PageInfo');
        log.info('DATA', data);
        this.formId = data.flowId.toUpperCase() + '-' + data.pageName.toUpperCase();
        this._pageInfo = data;
        this.windowRef.nativeWindow.spid = data.spId;
        this.pageValid = false;
        this.busy = false;
        this.disable = false;
        this.parentEventService.publishPageBusyStatus(false);
        this.rows = [];
        this.clearFormControls();
        this.pageForm.addControl('createPostDocCase', new FormControl(false));
        this.pageForm.addControl('createPostSaleCase', new FormControl(false));
        if (!isNullOrUndefined(this._pageInfo.components)) {
            let hasContinue = true;
            if (isNullOrUndefined(this._pageInfo.continueText) || this._pageInfo.continueText.length === 0) {
                hasContinue = false;
                if (this._pageInfo.mode === 'embedded') {
                    this.showCaseCreationOptions = true;
                }
            }
            let row = new Row();
            if (!isNullOrUndefined(this._pageInfo.components)) {
                for (let i = 0; i < this._pageInfo.components.length; i++) {
                    const component = this._pageInfo.components[i];
                    log.info('Add Component id: ' + component.id + ', type: ' + component.type);
                    component.isDynamic = true;
                    if (row.items.length === 0) {
                        this.rows.push(row);
                    } else if (row.items.length === 2) {
                        row = new Row();
                        this.rows.push(row);
                    }
                    row.items.push(component);
                    if (component.shareRow) {
                        component.colClass = 'col s12 m6';
                    } else {
                        component.colClass = 'col s12';
                        row = new Row();
                    }
                    switch (component.type) {
                        case 'text':
                            component.component = TextComponent;
                            break;
                        case 'number':
                            component.component = NumberComponent;
                            break;
                        case 'rsaid':
                            component.component = RsaIdComponent;
                            break;
                        case 'rsaiddob':
                            component.component = RsaIdDobComponent;
                            break;
                        case 'select':
                            component.component = SelectComponent;
                            break;
                        case 'product':
                            component.component = ProductComponent;
                            const products: ProductValuesVO[] = component.extraInput as ProductValuesVO[];
                            if (products.length > 0) {
                                component.isValid = true;
                            }
                            break;
                        case 'info':
                            component.component = InfoComponent;
                            component.isValid = true;
                            break;
                        case 'ok-disclosure':
                            component.component = OkDisclosureComponent;
                            component.isValid = true;
                            break;
                        case 'info-card':
                            component.component = InfoCardComponent;
                            component.isValid = true;
                            break;
                        case 'offer-info-card':
                            component.component = OfferInfoCardComponent;
                            component.isValid = true;
                            break;
                        case 'tel':
                            component.component = TelComponent;
                            break;
                        case 'email':
                            component.component = EmailComponent;
                            break;
                        case 'banking':
                            component.component = BankingComponent;
                            component.isValid = true;
                            break;
                        case 'beneficiary':
                            component.component = BeneficiariesComponent;
                            break;
                        case 'beneficiary-uk':
                            component.component = BeneficiariesUKComponent;
                            break;
                        case 'date-picker':
                            component.component = DatePickerComponent;
                            break;
                        case 'date':
                            component.component = DateComponent;
                            break;
                        case 'link-button':
                            component.component = LinkButtonComponent;
                            component.isValid = true;
                            break;
                        case 'resume-button':
                            component.component = ResumeButtonComponent;
                            component.isValid = true;
                            break;
                        case 'alt-continue':
                            component.component = AltContinueComponent;
                            component.isValid = true;
                            hasContinue = true;
                            break;
                        case 'radio-group':
                            component.component = RadioGroupComponent;
                            break;
                        case 'radio-group-horizontal':
                            component.component = RadioGroupHorizontalComponent;
                            break;
                        case 'check-group':
                            component.component = CheckboxGroupComponent;
                            break;
                        case 'check-box':
                            component.component = CheckboxComponent;
                            break;
                        case 'bmi':
                            component.component = BmiComponent;
                            component.isValid = true;
                            break;
                        case 'dot-org':
                            component.component = DotOrgComponent;
                            component.isValid = true;
                            break;
                        case 'stripe-element':
                            component.component = StripeElementComponent;
                            break;
                        case 'uk-post-code':
                            component.component = UkPostcodeComponent;
                            break;
                        case 'customise-cover':
                            component.component = CustomiseCoverComponent;
                            break;
                        case 'ajax-btn':
                            component.component = AjaxButtonComponent;
                            component.isValid = true;
                            if (!component.shareRow) {
                                component.colClass = 'col s12 m6 offset-m6';
                            }
                            break;
                        case 'mail-draft-doc':
                            component.component = MailDraftDocInfoComponent;
                            component.isValid = true;
                            break;
                        case 'mlf-main':
                            component.component = MLFMainComponent;
                            component.isValid = true;
                            break;
                        case 'mlf-summary':
                            component.component = MLFSummaryComponent;
                            component.isValid = true;
                            break;
                        case 'sf-main':
                            component.component = SFMainComponent;
                            component.isValid = true;
                            break;
                        case 'sf-summary':
                            component.component = SFSummaryComponent;
                            component.isValid = true;
                            break;
                        case 'hidden':
                            component.component = HiddenComponent;
                            component.isValid = true;
                            break;
                        case 'llc-main':
                            component.component = LLCMainComponent;
                            component.isValid = true;
                            break;
                        case 'llc-additional-products':
                            component.component = LlcAdditionalProductsComponent;
                            component.isValid = true;
                            break;
                        case 'quick-quote': {
                            component.component = QqComponent;
                            // component.isValid = false;
                            component.name = 'qq-mlf';
                            component.type = 'mlf';
                            break;
                        }
                        case 'pant-size':
                            component.component = PantSizeComponent;
                            break;
                        default:
                            log.warn('No component for ' + component.id + ' of type ' + component.type);
                            continue;
                    }
                    component.pageForm = this.pageForm;
                    component.counter = i + 1;
                    // increase counter by one to fix tabbing
                    component.counter++;
                }
                if (this._pageInfo.continueShareRow) {
                    const continueButton = new PageComponentVO();
                    continueButton.isDynamic = false;
                    continueButton.shareRow = true;
                    continueButton.colClass = 'col m6 s12';
                    const lastComponent = this._pageInfo.components[this._pageInfo.components.length - 1];
                    if (lastComponent.type === 'alt-continue' && lastComponent.shareRow) {
                        lastComponent.colClass = 'col s6';
                        continueButton.colClass = 'col s6';
                    }
                    // add to last row.
                    this.rows[this.rows.length - 1].items.push(continueButton);
                }
            }
            if (!hasContinue) {
                log.info('No Continue, set displayWarning off');
                this.windowRef.nativeWindow.displayWarning = false;
            }
        }
        this.checkPageValidity();
        this.onChanges();
    }
    get pageInfo(): PageInfoVO {
        return this._pageInfo;
    }

    @Output()
    continueEvent: EventEmitter<PageInfoVO> = new EventEmitter();

    ngOnDestroy(): void {
        this.busySubscription.unsubscribe();
    }

    doDynamicCompLogic(comp: PageComponentVO): void {
        this.checkPageValidity();
        switch (comp.component) {
            case SelectComponent: {
                if (this._pageInfo.flowId === 'MLF' || 'LLCF') {
                    if (comp.id === 'beneficiaryRelation') {
                        this.changeFormEstateFields(comp.value);
                        this.checkPageValidity();
                    }
                }
            }
        }
    }

    checkPageValidity(): void {
        // if (this._pageInfo.pageName === 'beneficiaries') {
        //     for (const ctrl of this._pageInfo.components) {
        //         if(ctrl.id === 'beneficiaryRelation' && ctrl.value !== null) {
        //             this.changeFormEstateFields(ctrl.value);
        //         }
        //     }
        // }

        let valid = true;
        if (!isNullOrUndefined(this._pageInfo.components)) {
            for (let i = 0; i < this._pageInfo.components.length; i++) {
                // log.info('Component [' + this._pageInfo.components[i].name + '], isValid [' + this._pageInfo.components[i].isValid + ']');
                if (!this._pageInfo.components[i].isValid) {
                    valid = false;
                    break;
                }
            }
            this.pageValid = valid;
        } else {
            this.pageValid = false;
        }
        // publish validated status to all listening children
        this.parentEventService.publishPageValidatedStatus(this.pageValid);
    }

    changeFormEstateFields(value) {
        this.enableOrDisableComponentsForEstate('beneficiaryIdDob', value !== 'estate');
        this.enableOrDisableComponentsForEstate('beneficiaryPhone', value !== 'estate');
        this.enableOrDisableComponentsForEstate('beneficiaryEmail', value !== 'estate');
    }

    enableOrDisableComponentsForEstate(id, enable) {
        for (const ctrl of this._pageInfo.components) {
            if (ctrl.id === id) {
                if (enable) {
                    ctrl.pageForm.get(id).enable();
                } else {
                    ctrl.pageForm.get(id).disable();
                    ctrl.isValid = true;
                }
                break;
            }
        }
    }

    onPostDocsChanged(): void {
        log.info('post doc clicked');
        if (this.getBooleanValueFromForm('createPostDocCase')) {
            log.info('post doc clicked and true');
            const dialogRef = this.dialog.open(NotesDialogComponent, {
                disableClose: true,
                position: { top: '20px' },
                maxWidth: '99vw',
                panelClass: 'dl-modal',
                data: { title: 'Please specify the address', notes: '' }
            });

            dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed: ' + result);
                this.addressNotes = 'Address: \n' + result;
            });
        }
    }

    onPostSaleCaseChanged(): void {
        log.info('post sale clicked');
        if (this.getBooleanValueFromForm('createPostSaleCase')) {
            log.info('post sale clicked and true');
            const dialogRef = this.dialog.open(NotesDialogComponent, {
                disableClose: true,
                position: { top: '20px' },
                maxWidth: '99vw',
                panelClass: 'dl-modal',
                data: { title: 'What does the client need assistance with?', notes: '' }
            });

            dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed: ' + result);
                this.caseNotes = result;
            });
        }
    }

    onChanges(): void {

        this.pageForm.get('createPostDocCase').valueChanges.subscribe(val => {
            if (this.getBooleanValueFromForm('createPostDocCase') || this.getBooleanValueFromForm('createPostSaleCase')) {
                this.createCaseEnabled = true;
            } else {
                this.createCaseEnabled = false;
            }
        });

        this.pageForm.get('createPostSaleCase').valueChanges.subscribe(val => {
            if (this.getBooleanValueFromForm('createPostDocCase') || this.getBooleanValueFromForm('createPostSaleCase')) {
                this.createCaseEnabled = true;
            } else {
                this.createCaseEnabled = false;
            }
        });
    }

    itemSetupComplete(): void {
        let all_loaded = true;
        if (!isNullOrUndefined(this._pageInfo.components)) {
            for (const item of this.pageInfo.components) {
                if (!item.loaded) {
                    all_loaded = false;
                    break;
                }
            }
        }
        if (all_loaded) {
            setTimeout(() => {
                this.resetForm();
                if (!isNullOrUndefined(this.dspService.currentData) && !isNullOrUndefined(this.dspService.currentData.components)) {
                    for (const comp of this.dspService.currentData.components) {
                        comp.dynamicControlRef.setInitialValue();
                    }
                }
                // publish validated status to all listening children
                this.parentEventService.publishPageValidatedStatus(this.pageValid);
            });
        }
    }

    /**
     * this function is fired when a child component that needs extra validation has a result.
     * @param {ValidationResult} result
     */
    itemValidated(result: ValidationResult): void {
        if (result.success) {
            if (!isNullOrUndefined(this.continueEvent)) {
                this.continueEvent.emit(this.pageInfo);
            }
        } else {
            this.busy = false;
            this.disable = false;
            this.parentEventService.publishPageBusyStatus(false);
        }
    }

    resetForm(): void {
        this.pageForm.reset();
        for (const item in this.pageForm.controls) {
            if (this.pageForm.controls.hasOwnProperty(item)) {
                this.pageForm.get(item).reset();
                this.pageForm.get(item).setErrors(null);
            }
        }
    }

    continueClick(): void {
        if (this.busy) {
            return;
        }
        this.busy = true;
        this.continue();
    }

    continue(): void {
        this.disable = true;
        this.parentEventService.publishPageBusyStatus(true);
        let waitForValidation = false;
        for (const ctrl of this._pageInfo.components) {
            if (ctrl.dynamicControlRef.validate()) {
                waitForValidation = true;
            }
        }
        if (!waitForValidation) {
            if (!isNullOrUndefined(this.continueEvent)) {
                this.continueEvent.emit(this.pageInfo);
            }
        }
    }

    get disableContinue(): boolean {
        this.checkPageValidity();
        // log.info('pageValid [' + this.pageValid + '], formInvalid [' + this.pageForm.invalid + '], busy [' + this.busy + '], disable [' + this.disable + ']');
        return !this.pageValid || this.pageForm.invalid || this.busy || this.disable;
    }

    resume(mustResume: boolean): void {
        if (mustResume) {
            this.pageInfo = this.dspService.resumeStorage;
            this.dspService.currentData = this.dspService.resumeStorage;
        }
    }

    altContinue(eventName: string): void {
        if (this.busy) {
            return;
        }
        if (!isNullOrUndefined(eventName) && eventName !== '') {
            this.pageInfo.continueValue = eventName;
        }
        this.continue();
    }


    private clearFormControls() {
        for (const item in this.pageForm.controls) {
            if (item !== 'placeholder') {
                this.pageForm.removeControl(item);
            } else {
                this.pageForm.get(item).reset();
            }
        }
        this.pageForm.reset();
    }

    get showTitle(): boolean {
        return !isNullOrUndefined(this.pageInfo) && !isNullOrUndefined(this.pageInfo.title) && this.pageInfo.title !== '';
    }

    get pageClass(): string {
        if (!this.showTitle) {
            return 'page no-title';
        }
        return 'page';
    }

    public setNotBusy(): void {
        this.busy = false;
        this.disable = false;
    }

    private getBooleanValueFromForm(formKey: string): boolean {
        return this.pageForm.get(formKey).value as boolean;
    }

    createCases(): void {
        this.createCaseEnabled = false;
        this.caseOptionsDisabled = true;
        if (this.getBooleanValueFromForm('createPostDocCase')) {
            log.info('creating post doc case');
            if (!isNullOrUndefined(this._pageInfo.policyId)) {
                this.dspService.createPostDocCase(this._pageInfo.policyId, this.addressNotes).subscribe(() => {
                    log.info('post doc case created');
                    this.casesCreated = true;
                });
            }
        }

        if (this.getBooleanValueFromForm('createPostSaleCase')) {
            log.info('creating post sale case');
            if (!isNullOrUndefined(this._pageInfo.contactId)) {
                this.dspService.createPostSaleServiceCase(this._pageInfo.contactId, this.caseNotes).subscribe(() => {
                    log.info('post sale case created');
                    this.casesCreated = true;
                });
            }
        }
    }

    async updateCaseForWebAvsInvalid(): Promise <boolean> {
        if (!isNullOrUndefined(this._pageInfo.spId)) {
            let success = await this.dspService.updateTheCaseForWebAvsInvalid(this._pageInfo.spId);

            if (success) {
                log.info('Case updated to customer_responded');
                return true;
            } else {
                log.info('Case not updated');
                return false;
            }
        }
    }
}

class Row {
    items: PageComponentVO[] = [];
}
