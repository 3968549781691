import {Type} from '@angular/core';
import {Dynamic, DynamicControl} from './component/component';
import {RsaId} from '../base/rsa.id.validator';
import {FormGroup} from '@angular/forms';
import {DropDownValuesVO, BankingDropdownValuesVO} from '../base/select.vo';
import {MLFDependantVO} from '../base/mlf-summary/mlf-summary.service';
import {Payer} from '../base/payer/dl.payer.vo';
import {AdditionalProductVO} from '../base/additional-products/additional-products.service';
import { DisclosureVO } from '../base/disclosures/disclosures.vos';
import {DependantVO} from '../base/sf-summary/sf-summary.service';

/**
 * Created by corneliusbotha on 2017/07/20.
 */

export class PageInfoVO {
    public id: string;
    public pageName: string;
    public title: string;
    public components: PageComponentVO[] = [];
    public continueText: string;
    public continueValue: string;
    public leadName: string;
    public leadId: string;
    public contactId: string;
    public spId: string;
    public premium: number;
    public policyId: string;
    public benefitId: string;
    public contactEmail: string;
    public productDefault: string;
    public startDate: string;
    public debitDay: string;
    public income: string;
    public policyName: string;
    public campaignCode: string;
    public continueShareRow = false;
    public productCode: string;
    public mode: string;
    public flowId: string;
    public groupId: string;
    // public products: ProductSelectionVO;
    public products: any;
    public premiumPayerMode: string;

    public static copy(from: PageInfoVO): PageInfoVO {
        const copy = new PageInfoVO();
        copy.id = from.id;
        copy.pageName = from.pageName;
        copy.title = from.title;
        copy.continueText = from.continueText;
        copy.continueValue = from.continueValue;
        copy.leadName = from.leadName;
        copy.leadId = from.leadId;
        copy.contactId = from.contactId;
        copy.spId = from.spId;
        copy.premium = from.premium;
        copy.policyId = from.policyId;
        copy.benefitId = from.benefitId;
        copy.contactEmail = from.contactEmail;
        copy.productDefault = from.productDefault;
        copy.startDate = from.startDate;
        copy.debitDay = from.debitDay;
        copy.income = from.income;
        copy.policyName = from.policyName;
        copy.campaignCode = from.campaignCode;
        copy.continueShareRow = from.continueShareRow;
        copy.productCode = from.productCode;
        copy.mode = from.mode;
        copy.groupId = from.groupId;
        copy.flowId = from.flowId;
        copy.premiumPayerMode = from.premiumPayerMode;
        copy.products = from.products; // ProductSelectionVO.copy(from.products);
        for (const comp of from.components) {
            copy.components.push(PageComponentVO.copy(comp));
        }
        return copy;
    }
}

export class PageComponentVO {
    public pageForm: FormGroup;
    public dynamicControlRef: DynamicControl;
    public name: string;
    public type: string;
    public id: string;
    public value: boolean | string | string[] |
        ProductValuesVO | DisclosureVO[] | BankingDetailVO | BeneficiaryDetailVO[] | BmiDetailVO |
        StripInfoVO | CustomiseValueVO | AjaxVO | ProductBenefitVO | MLFDependantVO | AdditionalProductVO[] | DropDownValuesVO[] | DependantVO;
    public valueExtra: string | RsaIdDetailVO | DisclosureDetailVO | MLFDependantVO[] | AdditionalProductVO[] | DependantVO[];
    public initValue: string;
    public shareRow = true;
    public component: Type<Dynamic<PageComponentVO>>;
    public counter = 0;
    public colClass = '';
    public isValid = false;
    public extraInput: boolean | string |
        DropDownValuesVO[] | ProductValuesVO[] | BankingValuesVO | CardInfoVO |
        DotOrgVO | StripeInput | NumberValidationVO | DateValidationVO | CustomiseInputVO | AdditionalProductVO[];
    public loaded = false;
    public isDynamic = true;
    public info: string;
    public required = true;

    public static copy(from: PageComponentVO): PageComponentVO {
        const copy = new PageComponentVO();
        copy.name = from.name;
        copy.type = from.type;
        copy.id = from.id;
        copy.value = from.value;
        copy.valueExtra = from.valueExtra;
        copy.shareRow = from.shareRow;
        copy.counter = from.counter;
        copy.isValid = from.isValid;
        copy.isDynamic = from.isDynamic;
        copy.required = from.required;
        return copy;
    }
}

export class ProductSelectionVO {
    public name: string;
    public amount: string;
    public premium: string;

    public static copy(from: ProductSelectionVO): ProductSelectionVO {
        const copy = new ProductSelectionVO();
        copy.name = from.name;
        copy.amount = from.amount;
        copy.premium = from.premium;
        return copy;
    }
}

export class RsaIdDetailVO {
    public age: number;
    public gender: string;
    public dob: string;
    public dobYear: string;
    public dobMonth: string;
    public dobDay: string;

    public from(rsaId: RsaId): void {
        this.age = rsaId.getAge();
        this.gender = rsaId.getGender();
        this.dob = rsaId.getDOB();
        this.dobYear = rsaId.getDOBYear();
        this.dobMonth = rsaId.getDOBMonth();
        this.dobDay = rsaId.getDOBDay();
    }
}

export class BankingDetailVO {
    public id: string;
    public ref: string;
    public bankName = '';
    public bankNameCode = '';
    public branchCode = '';
    public accountType = '';
    public accountHolder = '';
    public accountNo = '';
    public validated = false;
    public premiumPayerMode = '';
    public payer: Payer;
}

export class BankingValuesVO {
    public bankNames: BankingDropdownValuesVO[];
    public accountTypes: DropDownValuesVO[];
}

export class ProductValuesVO {
    public id: string;
    public name: string;
    public desc: string;
    public selected = false;
    public detail: ProductDetailVO[];
    public amount: number;
    public productGroupId: string;
    public productGroupCode: string;
    public class: string;
    public benefit_id: string;
}

export class ProductDetailVO {
    public amount: string;
    public description: string;
    public amountVal: number;
    public code: string;
    public name: string;
    public benefit_id: string;
}

export class BeneficiaryDetailVO {
    public id: string;
    public counter = 0;
    public name = '';
    public personal_relation = '';
    public idDob = '';
    public email = '';
    public percentage = 0;
    public phone_number = '';
}

export class BmiDetailVO {
    public heightM: number;
    public weightKG: number;
    public heightImperial: string;
    public weightImperial: string;
    public bmi: number;
    public qAnswerId: string;
    public showM: boolean;
    public showKG: boolean;
}

export class ValidationResult {
    public componentId: string;
    public success: boolean;
}

export class DisclosureDetailVO {
    heading: string;
    wording: string;
    step: string;
    code: string;
}

export class CardInfoVO {
    title: string;
    action?: {
        value: string;
        text: string;
        default?: boolean;
    };
    link?: {
        href: string;
        text: string;
    };
}

export class DotOrgVO {
    premium: string;
    projectId: string;
    projectName: string;
    clientName: string;
}

export class NumberValidationVO {
    min: number;
    max: number;
}

export class DateValidationVO {
    format: string;
    minAge: number;
    maxAge: number;
    dob = false;
    pickerStartView: 'month' | 'year' | 'multi-year';
}

export class StripInfoVO {
    id: string; // token
    object: string;
    card: StripeCardVO;
    client_ip: string;
    created: number;
    livemode: boolean;
    type: string;
    used: boolean;
}

export class StripeCardVO {
    id: string; // card id
    object: string;
    address_city: string;
    address_country: string;
    address_line1: string;
    address_line1_check: string;
    address_line2: string;
    address_state: string;
    address_zip: string;
    address_zip_check: string;
    brand: string;
    country: string;
    cvc_check: string;
    dynamic_last4: string;
    exp_month: number;
    exp_year: number;
    funding: string;
    last4: string;
    metadata: any;
    name: string;
    tokenization_method: string;
}

export class StripeInput {
    publishKey: string;
    postalCode: string;
}

export class CustomiseInputVO {
    product: string;
    age: number;
    gender: string;
    smoker: string;
    bmiLoading: number;
    termMin: number;
    termMax: number;
    coverMin: number;
    coverMax: number;
    coverStep: number;
    personName: string;
    productName: string;
    isWol: boolean;
}

export class CustomiseValueVO {
    term: number;
    cover: number;
    premium: number;
}

export class AjaxVO {
    method: string;
    data: any;
}

export class AjaxRequestVO {
    id: string;
    flowId: string;
    groupId: string;
    leadId: string;
    contactId: string;
    spId: string;
    policyId: string;
    benefitId: string;
    method: string;
    data: any;

    public static fromPageInfo(pageInfo: PageInfoVO, ajax: AjaxVO): AjaxRequestVO {
        const request = new AjaxRequestVO();
        request.id = pageInfo.id;
        request.flowId = pageInfo.flowId;
        request.groupId = pageInfo.groupId;
        request.leadId = pageInfo.leadId;
        request.contactId = pageInfo.contactId;
        request.spId = pageInfo.spId;
        request.policyId = pageInfo.policyId;
        request.benefitId = pageInfo.benefitId;
        request.method = ajax.method;
        request.data = ajax.data;
        return request;
    }
}

export class ProductBenefitVO {
    id: string;
    name: string;
    product_code: string;
    denominatorFactor: number;
    multiplierFactor: number;
    numeratorFactor: number;
    benefit_amount: number;
    premium_amount: number;
    product_master_id: string;
    options: Array<Array<ProductBenefitOptionVO>>;
}

export class ProductBenefitOptionVO {
    name: string;
    benefit_amount: number;
    premium_amount: number;
}
