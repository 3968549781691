import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {BaseModule} from '../base.module';
import {
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatTooltipModule
} from '@angular/material';
import {TextMaskModule} from 'angular2-text-mask';
import {SFBaseSummaryComponent} from './sf-base-summary.component';
import {SFDependantModalComponent} from './sf-dependant-modal.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BaseModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        MatDialogModule,
        MatListModule,
        TextMaskModule,
        MatTooltipModule,
        MatSliderModule,
        MatRadioModule
    ],
    declarations: [
        SFBaseSummaryComponent,
        SFDependantModalComponent
    ],
    exports: [
        SFBaseSummaryComponent
    ],
    entryComponents: [
        SFDependantModalComponent
    ]
})
export class SFBaseSummaryModule {}
