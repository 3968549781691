import {NgModule} from '@angular/core';
import {
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatFormFieldModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {DspComponent} from './dsp.component';
import {ValidatorModule} from '../base/validator.module';
import {NavComponent} from './nav/nav.component';
import {SgNavComponent} from './nav/sg.nav.component';
import {PageComponent} from './loader/page.component';
import {DynamicComponent} from './loader/dynamic.component';
import {TextComponent} from './component/text.component';
import {RsaIdComponent} from './component/rsa.id.component';
import {SelectComponent} from './component/select.component';
import {AccordionModule} from '../base/accordion/accordion';
import {ProductComponent} from './component/product.component';
import {InfoComponent} from './component/info.component';
import {OkDisclosureComponent} from './component/ok-disclosure.component';
import {TelComponent} from './component/tel.component';
import {EmailComponent} from './component/email.component';
import {BankingComponent} from './component/banking.component';
import {BeneficiariesComponent} from './component/beneficiaries.component';
import {DlNavComponent} from './nav/dl.nav.component';
import {TextMaskModule} from 'angular2-text-mask';
import {ErrorModule} from '../base/error/error.module';
import {LinkButtonComponent} from './component/link.button.component';
import {ResumeButtonComponent} from './component/resume.button.component';
import {HttpClientModule} from '@angular/common/http';
import {NumberComponent} from './component/number.component';
import {PantSizeComponent} from './component/pantsize.component';
import {DatePickerComponent} from './component/date-picker.component';
import {AltContinueComponent} from './component/alt.continue.component';
import {RadioGroupComponent} from './component/radio.group.component';
import {CheckboxGroupComponent} from './component/checkbox.group.component';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {CheckboxComponent} from './component/checkbox.component';
import {BmiComponent} from './component/bmi.component';
import {BeneficiariesUKComponent} from './component/beneficiaries-uk.component';
import {DotOrgComponent} from './component/dot-org.component';
import {DotOrgModalComponent} from './dot-org-modal/dot-org-modal.component';
import {InfoCardComponent} from './component/info-card.component';
import {StripeElementComponent} from './component/stripe.element.component';
import {BaseModule} from '../base/base.module';
import {DateComponent} from './component/date.component';
import {UkPostcodeComponent} from './component/uk.postcode.component';
import {CustomiseCoverComponent} from './component/customise-cover.component';
import {OfferInfoCardComponent} from './component/offer-info-card.component';
import {AjaxButtonComponent} from './component/ajax.button.component';
import {DlStripeModule} from '../base/stripe/dl.stripe.module';
import {MailDraftDocInfoComponent} from './component/mail.draft.doc.info.component';
import {MLFMainComponent} from './component/mlf-main.component';
import {MLFSummaryComponent} from './component/mlf-summary.component';
import {RsaIdDobComponent} from './component/rsa.id.dob.component';
import {HiddenComponent} from './component/hidden.component';
import {MLFBaseSummaryModule} from '../base/mlf-summary/mlf-base-summary.module';
import {NotesDialogComponent} from '../sp/confirmation/confirmation.component';
import {DlPayerModalComponent} from '../base/payer/dl.payer.modal.component';
import {DlBankingModule} from '../base/banking/dl.banking.module';
import {LLCMainComponent} from './component/llc-main.component';
import {RadioGroupHorizontalComponent} from './component/radio.group.horizontal.component';
import {BaseAdditionalProductsModule} from '../base/additional-products/base-additional-products.module';
import {LlcAdditionalProductsComponent} from './component/llc-additional-products.component';
import { LlcSummaryModule } from '../base/llc-summary/llc-summary.module';
import { DisclosuresModule } from '../base/disclosures/disclosures.module';
import {SFMainComponent} from './component/sf-main.component';
import {SFBaseSummaryModule} from '../base/sf-summary/sf-base-summary.module';
import {SFSummaryComponent} from './component/sf-summary.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ValidatorModule,
        AccordionModule,
        MatButtonModule,
        MatInputModule,
        MatSelectModule,
        MatListModule,
        MatDialogModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatTooltipModule,
        MatCheckboxModule,
        TextMaskModule,
        ErrorModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatCardModule,
        MatSliderModule,
        BaseModule,
        DlStripeModule,
        MLFBaseSummaryModule,
        SFBaseSummaryModule,
        DlBankingModule,
        BaseAdditionalProductsModule,
        DisclosuresModule,
        LlcSummaryModule,
        MatFormFieldModule
    ],
    declarations: [
        DspComponent,
        NavComponent,
        SgNavComponent,
        DlNavComponent,
        PageComponent,
        DynamicComponent,
        TextComponent,
        RsaIdComponent,
        RsaIdDobComponent,
        SelectComponent,
        ProductComponent,
        InfoComponent,
        OkDisclosureComponent,
        TelComponent,
        EmailComponent,
        BankingComponent,
        BeneficiariesComponent,
        BeneficiariesUKComponent,
        LinkButtonComponent,
        ResumeButtonComponent,
        NumberComponent,
        DatePickerComponent,
        AltContinueComponent,
        RadioGroupComponent,
        CheckboxGroupComponent,
        CheckboxComponent,
        BmiComponent,
        DotOrgComponent,
        DotOrgModalComponent,
        InfoCardComponent,
        OfferInfoCardComponent,
        StripeElementComponent,
        DateComponent,
        UkPostcodeComponent,
        CustomiseCoverComponent,
        AjaxButtonComponent,
        MailDraftDocInfoComponent,
        MLFMainComponent,
        MLFSummaryComponent,
        SFMainComponent,
        SFSummaryComponent,
        HiddenComponent,
        LLCMainComponent,
        RadioGroupHorizontalComponent,
        LlcAdditionalProductsComponent,
        PantSizeComponent
    ],
    entryComponents: [
        TextComponent,
        RsaIdComponent,
        RsaIdDobComponent,
        SelectComponent,
        ProductComponent,
        InfoComponent,
        OkDisclosureComponent,
        TelComponent,
        EmailComponent,
        BankingComponent,
        BeneficiariesComponent,
        BeneficiariesUKComponent,
        LinkButtonComponent,
        ResumeButtonComponent,
        SgNavComponent,
        DlNavComponent,
        NumberComponent,
        DatePickerComponent,
        AltContinueComponent,
        RadioGroupComponent,
        CheckboxGroupComponent,
        CheckboxComponent,
        BmiComponent,
        DotOrgComponent,
        DotOrgModalComponent,
        InfoCardComponent,
        OfferInfoCardComponent,
        StripeElementComponent,
        DateComponent,
        UkPostcodeComponent,
        CustomiseCoverComponent,
        AjaxButtonComponent,
        MailDraftDocInfoComponent,
        MLFMainComponent,
        MLFSummaryComponent,
        SFMainComponent,
        SFSummaryComponent,
        HiddenComponent,
        NotesDialogComponent,
        LLCMainComponent,
        NotesDialogComponent,
        DlPayerModalComponent,
        RadioGroupHorizontalComponent,
        LlcAdditionalProductsComponent,
        PantSizeComponent
    ],
    exports: [
        DspComponent,
        MLFMainComponent,
        LLCMainComponent,
        SFMainComponent,
        RadioGroupHorizontalComponent
    ]
})
export class DspModule {
}
