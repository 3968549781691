<div class="mlf-summary">
    <div class="row">
        <div class="col s12">
            <table class="dl-table">
                <thead>
                <tr>
                    <th class="text-left">Name</th>
                    <th class="text-right hide-on-small-and-down">Cover Amount</th>
                    <th class="text-right">Premium</th>
                    <th></th>
                </tr>
                </thead>
                <ng-container *ngFor="let dependant of dependants; let idx = index">
                    <tr>
                        <td class="text-left">
                            <span class="dl-badge" [matTooltip]="getHint(dependant)" [matTooltipPosition]="'after'">{{getBadge(dependant)}}</span>
                            {{dependant.name}}
                            <span class="hide-on-med-and-up"><br>{{dependant.benefit_amount | dl_currency:true:0}}</span>
                        </td>
                        <td class="text-right hide-on-small-and-down">{{dependant.benefit_amount | dl_currency:true:0}}</td>
                        <td class="text-right">{{dependant.premium_amount | dl_currency:true:2}}</td>
                        <td class="text-right">
                            <i *ngIf="idx === 0 || (dependant.rel === 'Child' || dependant.rel === 'Spouse' || dependant.rel === 'Extended Family')" (click)="clickEdit(dependant, idx === 0)" class="fa fa-pencil"></i>
                            <span *ngIf="idx === 0" class="hide-on-small-and-down spacer"></span>
                            <span *ngIf="idx !== 0" class="hide-on-med-and-up"><br></span>
                            <i *ngIf="idx !== 0 && (dependant.rel === 'Child' || dependant.rel === 'Spouse' || dependant.rel === 'Extended Family' || dependant.rel === 'Paid Up')" (click)="clickRemove(dependant)" class="fa fa-times"></i>
                        </td>
                    </tr>
                </ng-container>
                <tfoot>
                <tr>
                    <td><b>Total</b></td>
                    <td class="hide-on-small-and-down"></td>
                    <td class="text-right"><b>{{totalPremium | dl_currency:true:2}}</b></td>
                    <td></td>
                </tr>
                <tfoot>
            </table>
        </div>
    </div>
        <div class="col s12">
            <p class="text-center">Do you want to add additional lives to this policy?</p>
        </div>
        <div class="col s8 offset-s2 m6 offset-m3 l4 offset-l4">
            <button class="dl-full-width"
                    mat-raised-button
                    tabindex="1"
                    [attr.data-name]="'add'"
                    [attr.data-event]="'true'"
                    [attr.data-event-category]="eventCategory"
                    [attr.data-event-name]="'add-edit dependant'"
                    [attr.data-event-label]="'add-edit dependant'"
                    (click)="clickAdd()">Add</button>
        </div>
</div>

