import { Log } from 'ng2-logger/client';
import { EventEmitter } from '@angular/core';
import { RsaId, rsaIdValidation } from '../rsa.id.validator';
import { BehaviorSubject } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { CoverOptionsVO, SFSummaryService, ProductPricingRequestVO, ModalData, ProductMapping, ChangeEvent, SimplePricingRequestVO, DependantVO } from "./sf-summary.service";
import { MatDialogRef } from "@angular/material/dialog";
import { ErrorVO } from "../error/error.vo";
import { DLUtil } from "../dl.util";
var log = Log.create('SF-Dependant-Modal');
var SFDependantModalComponent = /** @class */ (function () {
    function SFDependantModalComponent(dialogRef, fb, summaryService, data) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.fb = fb;
        this.summaryService = summaryService;
        this.data = data;
        this.isValid = false;
        this.isBusy = false;
        this.rsaId = new RsaId();
        log.info("in constructor");
        this.setupForm(data.main);
        if (data.main) {
            this.fetchCoverOptions(null, data.dependant.product);
        }
        if (!isNullOrUndefined(data.dependant.benefit_amount)) {
            this.benefit_amount = data.dependant.benefit_amount;
        }
        if (!isNullOrUndefined(data.dependant.premium_amount)) {
            this.premium_amount = data.dependant.premium_amount;
        }
        var productList = [];
        var count = this.canAdd();
        data.products.forEach(function (item) {
            if (!_this.displayList().includes(item.display)) {
                item.disabled = true;
            }
            else {
                item.disabled = item.disabled || count.get(item.display);
                productList.push(item);
            }
        });
        data.products = productList;
    }
    SFDependantModalComponent.prototype.setupForm = function (main) {
        var _this = this;
        this.coverOptions$ = new BehaviorSubject(new CoverOptionsVO('invalid'));
        this.changeEvents$ = new EventEmitter();
        if (main) {
            log.info('Main Member');
            this.form = this.fb.group({ x: [''] });
        }
        else {
            log.info('Dependant');
            this.form = this.fb.group({
                product: [this.data.dependant.product, [Validators.required]],
                first_name: [this.data.dependant.first_name, [Validators.required]],
                last_name: [this.data.dependant.last_name, [Validators.required]],
                gender: [this.data.dependant.gender, [Validators.required]],
                id_or_dob: [this.data.dependant.id_or_dob, [Validators.required, Validators.pattern('[0-9]*'), rsaIdValidation(true, this)]]
            });
            this.form.get('first_name').valueChanges.pipe(debounceTime(450)).subscribe();
            this.form.get('last_name').valueChanges.pipe(debounceTime(450)).subscribe();
            this.form.get('id_or_dob').valueChanges.pipe(debounceTime(450)).subscribe(function () {
                _this.rsaId.setId(_this.form.get('id_or_dob').value);
                if (!isNullOrUndefined(_this.rsaId.getGender())) {
                    _this.form.get('gender').setValue((_this.rsaId.getGender() === 'Male' ? 'M' : 'F'));
                }
                _this.data.products.forEach(function (item) {
                    var ageValidation = false;
                    if (item.display === 'Child')
                        ageValidation = _this.ageValidation(_this.rsaId.getAge(), 21);
                    else if (item.display === 'Extended Family')
                        ageValidation = _this.ageValidation(_this.rsaId.getAge(), 75);
                    else if (item.display === 'Spouse')
                        ageValidation = _this.ageValidation(_this.rsaId.getAge(), 65);
                    item.disabled = item.disabled || ageValidation;
                });
            });
            this.form.get('product').valueChanges.subscribe(function () { return _this.productSelected(); });
            // Initialize an Observable for consolidated, debounced change events
            this.changeEvents$.pipe(debounceTime(650), distinctUntilChanged(function (a, b) {
                return a.rsaId.getId() === b.rsaId.getId() && a.product === b.product;
            })).subscribe(
            // Update premiums for change events
            function ($event) { return _this.fetchCoverOptions($event.rsaId, $event.product); });
        }
    };
    SFDependantModalComponent.prototype.fetchCoverOptions = function (rsaId, product) {
        var _this = this;
        if (!this.data.main) {
            if (isNullOrUndefined(product) || product.length === 0) {
                this.invalidateSlider('no product selected');
                return;
            }
            if (isNullOrUndefined(rsaId.getId()) || (rsaId.getId().length > 8 && !rsaId.isValid())) {
                this.invalidateSlider('id is invalid');
                return;
            }
        }
        log.info('loading cover options for ' + product);
        this.isValid = false;
        this.form.disable({ onlySelf: true, emitEvent: false });
        this.coverOptions$.next(new CoverOptionsVO('loading'));
        var req = new SimplePricingRequestVO(this.data.spId, [new ProductPricingRequestVO(product)], this.data.dependants);
        this.summaryService.getPricing(req).subscribe(function (res) {
            _this.updateCoverOptions(product, res);
            _this.form.enable({ onlySelf: true, emitEvent: false });
        }, function (error) {
            log.error('Error getting Dependant Info', error);
            _this.form.enable({ onlySelf: true, emitEvent: false });
            var coverOptions = new CoverOptionsVO('invalid');
            coverOptions.error = ErrorVO.toErrorVO(error).message;
            _this.coverOptions$.next(coverOptions);
        });
    };
    SFDependantModalComponent.prototype.updateCoverOptions = function (product, res) {
        if (isNullOrUndefined(res))
            return;
        if (res.hasOwnProperty('error')) {
            var coverOptions = new CoverOptionsVO('invalid');
            coverOptions.error = res.error;
            this.isValid = false;
            this.coverOptions$.next(coverOptions);
        }
        else {
            res.status = 'valid';
            for (var _i = 0, _a = res.options; _i < _a.length; _i++) {
                var p = _a[_i];
                if (!isNullOrUndefined(this.benefit_amount) && p.cover === this.benefit_amount) {
                    this.benefit_amount = p.cover;
                    res.cover = p.cover;
                    this.premium_amount = p.premium;
                }
            }
            this.sliderPoints = res.options;
            this.product_master_id = res.product_master_id;
            this.pricing = res.pricing;
            this.isValid = false;
            this.coverOptions$.next(res);
        }
    };
    SFDependantModalComponent.prototype.invalidateSlider = function (message) {
        log.info(message);
        this.coverOptions$.next(new CoverOptionsVO('invalid'));
    };
    SFDependantModalComponent.prototype.formatDisplay = function (value) {
        return DLUtil.compactFormat(value, false);
    };
    SFDependantModalComponent.prototype.productSelected = function () {
        var rsaId = new RsaId();
        rsaId.setId(this.form.get('id_or_dob').value);
        var product = this.form.get('product').value;
        this.changeEvents$.emit(new ChangeEvent(rsaId, product));
    };
    Object.defineProperty(SFDependantModalComponent.prototype, "title", {
        get: function () {
            if (this.isDependant) {
                return (this.data.edit ? 'Edit' : 'Add') + ' additional life';
            }
            return 'Edit Main Member';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SFDependantModalComponent.prototype, "saveButtonText", {
        get: function () {
            return this.data.edit ? 'Save' : 'Add';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SFDependantModalComponent.prototype, "isDependant", {
        get: function () {
            return !this.data.main;
        },
        enumerable: true,
        configurable: true
    });
    SFDependantModalComponent.prototype.selectionChange = function (id) {
    };
    Object.defineProperty(SFDependantModalComponent.prototype, "leadName", {
        get: function () {
            return this.data.leadName;
        },
        enumerable: true,
        configurable: true
    });
    SFDependantModalComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    SFDependantModalComponent.prototype.onIdValidationChange = function (rsaId) {
        if (!isNullOrUndefined(this.form) &&
            !isNullOrUndefined(this.form.get('product')) &&
            !isNullOrUndefined(this.form.get('product').value) &&
            !isNullOrUndefined(this.form.get('gender').value)) {
            var product = this.form.get('product').value;
            this.changeEvents$.emit(new ChangeEvent(rsaId, product));
        }
    };
    Object.defineProperty(SFDependantModalComponent.prototype, "productHint", {
        get: function () {
            var product = this.form.get('product').value;
            if (!isNullOrUndefined(product))
                this.options = new ProductMapping().getProduct(this.determineProductGroup(product));
            else
                this.options = new ProductMapping().getProduct(this.determineProductGroup(this.data.dependants[0].product));
            if (!isNullOrUndefined(product) && !isNullOrUndefined(this.options))
                return this.getProductDescription(product);
            return null;
        },
        enumerable: true,
        configurable: true
    });
    SFDependantModalComponent.prototype.getProductDescription = function (product) {
        for (var _i = 0, _a = this.options; _i < _a.length; _i++) {
            var p = _a[_i];
            if (p.code === product)
                return p.description;
        }
        return null;
    };
    SFDependantModalComponent.prototype.determineProductGroup = function (product) {
        return product.split('_')[0];
    };
    SFDependantModalComponent.prototype.updateCover = function (val) {
        this.benefit_amount = val.value;
        this.premium_amount = this.findPremiumForCover(val.value);
        this.isValid = val.value !== 0;
        this.isValid = this.premium_amount !== 0;
        this.form.markAsDirty();
    };
    SFDependantModalComponent.prototype.findPremiumForCover = function (cover) {
        var premium = 0;
        this.sliderPoints.forEach(function (opt) {
            if (cover === opt.cover) {
                premium = opt.premium;
            }
        });
        return premium;
    };
    Object.defineProperty(SFDependantModalComponent.prototype, "disableSave", {
        get: function () {
            return !this.isValid || this.form.pristine || this.form.invalid;
        },
        enumerable: true,
        configurable: true
    });
    SFDependantModalComponent.prototype.save = function () {
        this.isBusy = true;
        var data = new ModalData();
        DLUtil.copyFields(this.data, data);
        data.dependant = new DependantVO();
        DLUtil.copyFields(this.data.dependant, data.dependant);
        DLUtil.copyFields(this.form.value, data.dependant);
        data.dependant.benefit_amount = this.benefit_amount;
        data.dependant.premium_amount = this.premium_amount;
        data.dependant.product_master_id = this.product_master_id;
        data.dependant.pricing = this.pricing;
        data.dependant.age = this.rsaId.getAge();
        this.dialogRef.close(data);
    };
    SFDependantModalComponent.prototype.displayList = function () {
        return ['Child', 'Spouse', 'Extended Family'];
    };
    SFDependantModalComponent.prototype.canAdd = function () {
        var count = new Map();
        var spouse = 0;
        var total = 0;
        count.set('Child', true);
        count.set('Spouse', false);
        count.set('Extended Family', true);
        for (var _i = 0, _a = this.data.dependants; _i < _a.length; _i++) {
            var dep = _a[_i];
            switch (dep.rel) {
                case 'Extended Family':
                case 'Child':
                    ++total;
                    break;
                case 'Spouse':
                    ++spouse;
                    break;
                default:
                    break;
            }
        }
        if (spouse === 1) {
            count.set('Spouse', true);
        }
        if (total < 8) {
            count.set('Child', false);
            count.set('Extended Family', false);
        }
        return count;
    };
    SFDependantModalComponent.prototype.ageValidation = function (age, limit) {
        if (age > limit) {
            return true;
        }
        else if (age <= limit && age > 0)
            return false;
    };
    return SFDependantModalComponent;
}());
export { SFDependantModalComponent };
